import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Page from '../../layouts/Account';
import Container from '../../components/ui/Container/container';
import { UserExamType } from '../../types/user';

// Services
import ModelApi from '../../services/model_endpoints';
import UserContractApi from '../../services/user_contract';
import { getLocalTimeString } from '../../utils/time_utils';
import { getHumanReadableUserExamStatus } from '../../utils/model_status_utils';

import Loading from '../../components/Loading';
import NotAuthenticated from '../../components/NotAuthenticated/NotAuthenticated';

const handleClick = (examId: string) => {
  window.location.replace('/account/start_exam/?examId=' + examId);
};

function MyExams() {
  const [exams, setExams] = useState<UserExamType[]>([]);
  const [remainingAttempt, setRemainingAttempts] = useState<number>(0);
  const { isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    async function fetchData() {
      if (user?.email !== undefined) {
        await UserContractApi.refreshUserExams(user?.email);
        const exams = await ModelApi.fetchUserExams(user?.email);
        setRemainingAttempts(exams[0].exam.maximumRetry - exams[0].attemptCount);
        setExams(exams);
      }
    }
    fetchData();
  }, [user?.email]);

  const theme = useTheme();

  if (isLoading) {
    return <Loading />;
  } else if (!isAuthenticated) {
    return <NotAuthenticated />;
  }

  return (
    <Page>
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead sx={{ bgcolor: 'alternate.dark' }}>
              <TableRow>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Exam Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Remaining Attempts
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Start Time
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Completed Time
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Score
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {exams.map((item: UserExamType, i: number) => (
                <TableRow
                  key={i}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:nth-of-type(2n)': { bgcolor: 'alternate.main' },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant={'subtitle2'} fontWeight={700}>
                      {item.exam.title}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography variant={'subtitle2'} fontWeight={700}>
                      {remainingAttempt}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {item.startTime && (
                      <Typography color={'text.secondary'} variant={'subtitle2'}>
                        {getLocalTimeString(item.startTime)}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography color={'text.secondary'} variant={'subtitle2'}>
                      {item.endTime && (
                        <Typography color={'text.secondary'} variant={'subtitle2'}>
                          {getLocalTimeString(item.endTime)}
                        </Typography>
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'caption'} fontWeight={700} sx={{ color: theme.palette.success.dark }}>
                      {getHumanReadableUserExamStatus(item.status)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={'text.secondary'} variant={'subtitle2'}>
                      {item.score}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {['INIT', 'PROGRESS'].indexOf(item.status) != -1 && remainingAttempt > 0 && (
                      <Typography
                        onClick={() => handleClick(item.id)}
                        color={'primary'}
                        variant={'subtitle2'}
                        fontWeight={700}
                        sx={{ cursor: 'pointer' }}
                      >
                        Start
                      </Typography>
                    )}

                    {['FAILED'].indexOf(item.status) != -1 && remainingAttempt > 0 && (
                      <Typography
                        onClick={() => handleClick(item.id)}
                        color={'primary'}
                        variant={'subtitle2'}
                        fontWeight={700}
                        sx={{ cursor: 'pointer' }}
                      >
                        Retake
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}

export default MyExams;
