// Routes
import { Route, Routes } from 'react-router-dom';

import { CA_STATE_ROUTE, FL_STATE_ROUTE, PRELICENSE_SALES_ROUTE_URL, TX_STATE_ROUTE } from '../constants';
import Course from '../../pages/Course/course';
import NotFound from '../../components/NotFound';

const RealEstatePreLicensePackageRoutes = () => {
  return (
    <Routes>
      <Route path={PRELICENSE_SALES_ROUTE_URL}>
        <Route path={CA_STATE_ROUTE} element={<Course />} />
        <Route path={TX_STATE_ROUTE} element={<>Texas</>} />
        <Route path={FL_STATE_ROUTE} element={<>Florida</>} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RealEstatePreLicensePackageRoutes;
