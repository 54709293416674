import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const BackHome = () => {
  return (
    <Button component={Link} variant="contained" color="primary" size="large" href={'/'}>
      Back home
    </Button>
  );
};

export default BackHome;
