import { Box, Button } from '@mui/material';

interface NavigationProps {
  isOnFirstQuestion: any;
  isAnswerNotSelected: any;
  handlePrevious: any;
  handleNext: any;
}

const NagivationButtons: React.FC<NavigationProps> = ({
  isOnFirstQuestion,
  isAnswerNotSelected,
  handlePrevious,
  handleNext,
}) => {
  return (
    <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
      <Box marginTop={1} marginRight={2}>
        <Button variant="contained" size="small" disabled={isOnFirstQuestion()} onClick={handlePrevious}>
          Previous
        </Button>
      </Box>
      <Box marginTop={1}>
        <Button variant="contained" size="small" disabled={isAnswerNotSelected()} onClick={handleNext}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default NagivationButtons;
