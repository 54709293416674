import React from 'react';
import { Box, Button, Grid } from '@mui/material';

import Segment from './components/Segment';

interface InstructionsProps {
  onStartExam: (choice: any) => void;
}

const Instructions: React.FC<InstructionsProps> = ({ onStartExam }) => {
  return (
    <Box>
      <Grid container>
        <Grid>
          <Segment
            title={'Real Estate Agent Examination Instructions'}
            paragraph={'Please review the following instructions to ensure a smooth examination experience.'}
            bulletPoints={[]}
          />
          <Segment
            title={'Before Starting the Exam'}
            bulletPoints={[
              'Ensure your computer has a stable internet connection.',
              'Close all unnecessary applications.',
              'Find a quiet, well-lit room where you can work undisturbed.',
              'Ensure your workspace is clear of notes, books, or any unauthorized aids.',
              'Have a valid government-issued ID ready for verification purposes.',
            ]}
          />
          <Segment
            title={'Navigating the Exam'}
            bulletPoints={[
              'Read each question and its options carefully before selecting an answer.',
              'A timer will be visible throughout the exam, indicating the time remaining.',
              'Use the "Next" and "Previous" buttons to move between questions.',
              "Do not use your browser's back or forward buttons.",
              'Do not leave the website until you finish the exam.',
            ]}
          />
          <Segment
            title={'Additional Information'}
            bulletPoints={[
              'This exam consists of 150 multiple-choice questions.',
              'This exam is 3 hours long and timed continuously.',
              'If you need to take a break, the timer will not pause.',
              'The exam will automatically end when the time runs out.',
              'You must score atleast 70% or higher to pass the exam.',
            ]}
          />
          <Button variant="contained" color="primary" size="large" onClick={onStartExam}>
            Start now
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Instructions;
