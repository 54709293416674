import Typography from '@mui/material/Typography';

import { FormControl } from '@mui/material';
import MultipleChoiceOption from '../MultipleChoiceOption';
import { UserAnswerType } from '../../../../types/user';

interface MultipleChoiceQuestionProps {
  question: {
    num: number;
    prompt: string;
    choices: Array<{ id: string; text: string }>;
  };
  selectedAnswers: UserAnswerType[];
  currentQuestionIndex: number;
  setSelectedAnswers: any;
}

const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({
  question,
  selectedAnswers,
  currentQuestionIndex,
  setSelectedAnswers,
}) => {
  return (
    <FormControl>
      <Typography variant="h6">
        {question.num + 1}) {question.prompt}
      </Typography>
      <MultipleChoiceOption
        question={question}
        selectedAnswers={selectedAnswers}
        currentQuestionIndex={currentQuestionIndex}
        setSelectedAnswers={setSelectedAnswers}
      />
    </FormControl>
  );
};

export default MultipleChoiceQuestion;
