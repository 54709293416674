import { CourseType, PackageType } from '../types/system';
import { UserType } from '../types/user';
import { UserExamType } from '../types/user';
import { EnrollmentType } from '../types/user';
import { getEnvValues } from '../utils/env_utils';

const BASE_URL = getEnvValues().baseApiUrl;

export default {
  fetchPackages: async (): Promise<PackageType[]> => {
    const endpoint = `${BASE_URL}/packages`;
    return await (await fetch(endpoint)).json();
  },
  fetchCourses: async (): Promise<CourseType[]> => {
    const endpoint = `${BASE_URL}/courses`;
    return await (await fetch(endpoint)).json();
  },
  fetchUsers: async (): Promise<UserType[]> => {
    const endpoint = `${BASE_URL}/users`;
    return await (await fetch(endpoint)).json();
  },
  fetchUser: async (userId: string): Promise<UserType> => {
    const endpoint = `${BASE_URL}/user/?id=${encodeURIComponent(userId)}`;
    return await (await fetch(endpoint)).json();
  },
  fetchUserExams: async (userId: string): Promise<UserExamType[]> => {
    const endpoint = `${BASE_URL}/user_exams/getByUser?userId=${encodeURIComponent(userId)}`;
    return await (await fetch(endpoint)).json();
  },
  fetchUserExam: async (examId: string): Promise<UserExamType> => {
    const endpoint = `${BASE_URL}/user_exams/getById?examId=${examId}`;
    return await (await fetch(endpoint)).json();
  },
  fetchEnrollmentsByUser: async (userId: string): Promise<EnrollmentType[]> => {
    const endpoint = `${BASE_URL}/user_enrollment/getByUser?userId=${encodeURIComponent(userId)}`;
    return await (await fetch(endpoint)).json();
  },
  fetchEnrollments: async (): Promise<EnrollmentType[]> => {
    const endpoint = `${BASE_URL}/user_enrollments`;
    return await (await fetch(endpoint)).json();
  },
};
