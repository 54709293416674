import path from 'path-browserify';

import {
  CA_STATE_ROUTE,
  FL_STATE_ROUTE,
  PACKAGE_ROUTE_URL,
  PRELICENSE_SALES_ROUTE_URL,
  REALESTATE_ROUTE,
  TX_STATE_ROUTE,
} from './constants';

export function joinPaths(...paths: string[]) {
  return path.join(...paths);
}

export function getRealEstateLicensePathByState(stateCode: string): string {
  const baseUrl = joinPaths(PACKAGE_ROUTE_URL, REALESTATE_ROUTE, PRELICENSE_SALES_ROUTE_URL);
  if (stateCode === 'CA') {
    return joinPaths(baseUrl, CA_STATE_ROUTE);
  } else if (stateCode === 'FL') {
    return joinPaths(baseUrl, FL_STATE_ROUTE);
  } else if (stateCode === 'TX') {
    return joinPaths(baseUrl, TX_STATE_ROUTE);
  }
  throw new Error('stateCode={stateCode} not supported in getRealEstateLicensePathByState');
}
