import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { NavItem } from './components';

function isUserAdmin(emailAddress: string): boolean {
  const admins = ['thn531@icloud.com'];
  return admins.indexOf(emailAddress.toLowerCase()) >= 0;
}


const Topbar = ({ onSidebarOpen, pages}) => {
  const theme = useTheme();

  const {
    realestate: realestatePages,
    blog: blogPages,
    guide: guidePages,
    myCourses: myCoursesPages,
    adminPages: adminNav,
  } = pages;

  const { user, isAuthenticated, isLoading, loginWithRedirect, logout} = useAuth0();


  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="theFront"
        width={{ xs: 150, md: 200 }}
      >
        <Box
          component={'img'}
          src={'/images/logo/logo.png'}
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box>
          {/* <NavItem
            title={'Real Estate'}
            id={'realestate-pages'}
            items={realestatePages}
          /> */}
        </Box>
        {/* <Box marginLeft={4}>
          <NavItem
            title={'Blog'}
            id={'blog'}
            items={blogPages}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Career Guides'}
            id={'career-guides'}
            items={guidePages}
          />
        </Box> */}
        {isAuthenticated ? (
          <Box marginLeft={4}>
            <NavItem
              title={'My Courses'}
              id={'my-courses'}
              items={myCoursesPages}
            />
          </Box>
        ) : (
          <></>
        )}

        {isAuthenticated && isUserAdmin(user.email) ? (
          <Box marginLeft={4}>
            <NavItem
              title={'Admin'}
              id={'admin'}
              items={adminNav}
            />
          </Box>
        ) : (
          <></>
        )}

        <Box marginLeft={4}>
          {isAuthenticated ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                size="large"
              >
                Logout
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => loginWithRedirect()}
                size="large"
              >
                Login
              </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
