import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

import NotFound from '../../components/NotFound';

import Instructions from './components/Instruction';
import Exam from './components/Exam';
import Page from '../../layouts/Account/Account';

// Services
import ModelApi from '../../services/model_endpoints';
import UserContractApi from '../../services/user_contract';
import { UserExamType } from '../../types/user';
import NotAuthenticated from '../../components/NotAuthenticated/NotAuthenticated';
import Loading from '../../components/Loading';

const Overview = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [examStarted, setExamStarted] = useState(false);

  /* How to define a null exam type same? */
  const [userExams, setUserExam] = useState<UserExamType[]>([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const examId = queryParams.get('examId') || '';

  useEffect(() => {
    async function fetchData() {
      const userExam = await ModelApi.fetchUserExam(examId);
      setUserExam([userExam]);
    }
    fetchData();
  }, [examId]);

  const handleStartExam = async () => {
    await UserContractApi.startExam(examId);
    setExamStarted(true);
  };

  if (!examId) {
    return <NotFound />;
  } else if (isLoading) {
    return <Loading />;
  } else if (!isAuthenticated) {
    return <NotAuthenticated />;
  }

  return (
    <Page>{!examStarted ? <Instructions onStartExam={handleStartExam} /> : <Exam userExam={userExams[0]} />}</Page>
  );
};

export default Overview;
