import { useEffect, useState } from 'react';
import { Container } from '@mui/material';

import MultipleChoiceQuestion from '../MultipleChoiceQuestion';
import NagivationButtons from '../NagivationButtons';
import Completion from '../Completion';
import Timer from '../Timer/Timer';

// Services
import UserContractApi from '../../../../services/user_contract';

import { UserAnswerType, UserExamStatus, UserExamType } from '../../../../types/user';
import moment from 'moment';

interface ExamProps {
  userExam: UserExamType;
}

const saveAnswers = async (examId: string, answers: UserAnswerType[]) => {
  UserContractApi.finishExam(examId, answers);
};

function calcRemainingTime(userExam: UserExamType): number {
  if (userExam.status === UserExamStatus.INIT || userExam.status === UserExamStatus.FAILED) {
    return userExam.exam.timeLimitInSeconds;
  } else if (userExam.status == UserExamStatus.PROGRESS && userExam.startTime !== null) {
    const startTime = moment.utc(userExam.startTime);
    const diffMilliseconds = moment.utc().diff(startTime);
    const diffSeconds = Math.round(diffMilliseconds / 1000);
    return Math.max(0, userExam.exam.timeLimitInSeconds - diffSeconds);
  } else {
    return 0;
  }
}

const Exam: React.FC<ExamProps> = ({ userExam }) => {
  let timerId: any;
  const exam = userExam.exam;
  const questions = exam.questions;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(calcRemainingTime(userExam));
  const [examEnded, setExamEnded] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState<UserAnswerType[]>(new Array(questions.length).fill(null));
  const [hasSavedAnswers, setHasSavedAnswered] = useState(false);

  useEffect(() => {
    timerId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 1) {
          setExamEnded(true);
          clearInterval(timerId);
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  const handleNext = () => {
    if (!isOnLastQuestion()) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      setExamEnded(true);
    }
  };

  const handlePrevious = () => {
    if (!isOnFirstQuestion()) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const isOnFirstQuestion = () => {
    return currentQuestionIndex == 0;
  };

  const isOnLastQuestion = () => {
    return currentQuestionIndex == questions.length - 1;
  };

  const isAnswerNotSelected = () => {
    return selectedAnswers[currentQuestionIndex] === null;
  };

  if (examEnded) {
    clearInterval(timerId);
    if (!hasSavedAnswers) {
      setHasSavedAnswered(true);
      saveAnswers(userExam.id, selectedAnswers);
    }
    return <Completion timerExpired={timeLeft === 0} />;
  }

  return (
    <Container>
      <MultipleChoiceQuestion
        question={questions[currentQuestionIndex]}
        currentQuestionIndex={currentQuestionIndex}
        selectedAnswers={selectedAnswers}
        setSelectedAnswers={setSelectedAnswers}
      />
      <NagivationButtons
        isOnFirstQuestion={isOnFirstQuestion}
        isAnswerNotSelected={isAnswerNotSelected}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
      <Timer timeLeft={timeLeft} />
    </Container>
  );
};

export default Exam;
