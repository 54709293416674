import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { alpha, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';

import Page from '../../layouts/Account';

// Services
import API from '../../services/model_endpoints';
import admin_contract from '../../services/admin_contract';
import { EnrollmentType } from '../../types/user';
import Container from '../../components/ui/Container/container';

const handleReset = (enrollmentId: string) => {
  admin_contract.resetEnrollmentAndRegistration(enrollmentId);
  document.location.reload();
};

const handleDelete = (enrollmentId: string) => {
  admin_contract.deleteEnrollementAndRegistration(enrollmentId);
  document.location.reload();
};

const handleComplete = (enrollmentId: string) => {
  admin_contract.markEnrollmentCompleted(enrollmentId);
  document.location.reload();
};

const handleLaunchLink = (url: string) => {
  window.open(url, '_blank');
};

const refreshEnrollment = (enrollmentId: string) => {
  admin_contract.refreshEnrollment(enrollmentId);
  document.location.reload();
};

function Enrollment() {
  const theme = useTheme();
  const { user } = useAuth0();

  const [enrollments, setEnrollments] = useState<EnrollmentType[]>([]);
  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      if (user !== undefined && user?.email !== undefined) {
        const apiUser = await API.fetchUser(user?.email);
        if (apiUser.isSuperUser) {
          const enrollments = await API.fetchEnrollments();
          setEnrollments(enrollments);
        }
        setIsSuperUser(apiUser.isSuperUser);
      }
    }
    fetchData();
  }, [user]);

  /* Todo: Non-super admin component */
  if (!isSuperUser) {
    return <></>;
  }

  return (
    <Page>
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead sx={{ bgcolor: 'alternate.main' }}>
              <TableRow>
                <TableCell>
                  <Typography
                    color={'text.secondary'}
                    variant={'caption'}
                    fontWeight={700}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color={'text.secondary'}
                    variant={'caption'}
                    fontWeight={700}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    State
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color={'text.secondary'}
                    variant={'caption'}
                    fontWeight={700}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Package Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color={'text.secondary'}
                    variant={'caption'}
                    fontWeight={700}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Course Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color={'text.secondary'}
                    variant={'caption'}
                    fontWeight={700}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color={'text.secondary'}
                    variant={'caption'}
                    fontWeight={700}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Time Spent (minutes)
                  </Typography>
                </TableCell>
                <TableCell>Modifications</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enrollments.map((item, i) => (
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <List sx={{ p: 0, m: 0 }}>
                      <ListItem sx={{ p: 0, m: 0 }}>
                        <ListItemAvatar>
                          <Avatar src="https://assets.maccarianagency.com/avatars/img5.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.user.firstName + ' ' + item.user.lastName}
                          secondary={item.user.emailAddress}
                        />
                      </ListItem>
                    </List>
                  </TableCell>
                  <TableCell>
                    <Typography>{item.course.package.state}</Typography>
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={item.course.package.title} secondary={item.course.package.code} />
                  </TableCell>
                  <TableCell>
                    <ListItemText primary={item.course.title} secondary={item.course.code} />
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{
                        bgcolor: alpha(theme.palette.success.light, 0.1),
                        color: theme.palette.success.dark,
                        paddingX: 1.5,
                        paddingY: 0.5,
                        borderRadius: 4,
                        display: 'inline',
                      }}
                    >
                      {item.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{item.timeSpentInMinute}</Typography>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => refreshEnrollment(item.id)}>Refresh Enrollment</Button>
                    <Button onClick={() => handleLaunchLink(item.launchLink)}>Launch Course</Button>
                    <Button onClick={() => handleReset(item.id)}>Reset Enrollment</Button>
                    <br />
                    <Button onClick={() => handleDelete(item.id)}>Delete Enrolment</Button>
                    <br />
                    <Button onClick={() => handleComplete(item.id)}>Mark Completed</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}

export default Enrollment;
