import { UserEnrollmentStatus, UserExamStatus } from '../types/user';

export function getHumanReadableUserExamStatus(status: string): string {
  switch (status) {
    case UserExamStatus.INIT:
      return 'Not Started';
    case UserExamStatus.PROGRESS:
      return 'In Progress';
    case UserExamStatus.FAILED:
      return 'Failed';
    case UserExamStatus.PASSED:
      return 'Passed';
    default:
      return 'Unknown';
  }
}

export function getHumanReadableUserEnrollmentStatus(status: string): string {
  switch (status) {
    case UserEnrollmentStatus.INIT:
      return 'Not started';
    case UserEnrollmentStatus.PROGRESS:
      return 'In Progress';
    case UserEnrollmentStatus.COMPLETED:
      return 'Finished';
    default:
      return 'Unknown';
  }
}
