import { Box, LinearProgress } from '@mui/material';

function Loading() {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  );
}

export default Loading;
