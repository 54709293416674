import { Route, Routes, useParams } from 'react-router-dom';
import NotFound from '../components/NotFound';

const CourseInfo = () => {
  const { courseId } = useParams();

  return (
    <div>
      <h4>Course ID: {courseId}</h4>
    </div>
  );
};

const CourseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<>Course Home 1</>} />
      <Route path=":courseId" element={<CourseInfo />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default CourseRoutes;
