import { Route, Routes } from 'react-router-dom';

import Enrollment from '../pages/SuperAdmin/Enrollment';
import User from '../pages/SuperAdmin/User';
import NotFound from '../components/NotFound';

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/enrollments" element={<Enrollment />} />
      <Route path="/users" element={<User />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRoutes;
