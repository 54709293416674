import { CourseType, ExamType, ExtraType, MultipleChoiceOptionType, MultipleChoiceQuestionType } from './system';

type UserType = {
  firstName: string;
  lastName: string;
  isSuperUser: boolean;
  emailAddress: string;
  extra: ExtraType;
};

type UserEnrollmentType = {
  id: string;
  launchLink: string;
  progressPercent: number;
  status: string;
  timeSpentInMinute: number;
  user: UserType;
  course: CourseType;
  extra: ExtraType;
};

export enum UserEnrollmentStatus {
  INIT = 'INIT',
  PROGRESS = 'PROGRESS',
  COMPLETED = 'Completed',
}

type UserExamType = {
  id: string;
  exam: ExamType;
  attemptCount: number;
  status: string;
  startTime?: string;
  endTime?: string;
  score?: number;
  answers: MultipleChoiceQuestionType[];
};

export enum UserExamStatus {
  INIT = 'INIT',
  PROGRESS = 'PROGRESS',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
}

type UserAnswerType = {
  num: number;
  prompt: string;
  answer: MultipleChoiceOptionType;
};

export type { UserType, UserEnrollmentType as EnrollmentType, UserExamType, UserAnswerType };
