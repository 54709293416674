import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Page from '../../layouts/Account';

// Services
import API from '../../services/model_endpoints';
import admin_contract from '../../services/admin_contract';
import { CourseType, PackageType } from '../../types/system';
import { UserType } from '../../types/user';
import Container from '../../components/ui/Container/container';

const handleAddPackageChange = (event: PointerEvent) => {
  if (event.target != null) {
    // const data = event.target.value;
    // const [userId, packageCode] = data.split("||");
    admin_contract.createEnrollmentsForUser('userId', 'packageCode');
  }
};

function User() {
  const { user } = useAuth0();

  const [allUsers, setAllUsers] = useState<UserType[]>([]);
  const [allCourses, setCourses] = useState<CourseType[]>([]);
  const [allPackages, setPackages] = useState<PackageType[]>([]);
  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
  // const [courseCode, setCourseCode] = useState<string>('');
  const [packageCode, setPackageCode] = useState<string>('');

  useEffect(() => {
    async function fetchData() {
      if (user !== undefined && user?.email !== undefined) {
        const apiUser = await API.fetchUser(user?.email);
        if (apiUser.isSuperUser) {
          const users = await API.fetchUsers();
          const courses = await API.fetchCourses();
          const packages = await API.fetchPackages();
          setAllUsers(users);
          setCourses(courses);
          setPackages(packages);
        }
        setIsSuperUser(apiUser.isSuperUser);
      }
    }
    fetchData();
  }, [user]);

  /* Todo: Non-super admin component */
  if (!isSuperUser) {
    return <></>;
  }

  return (
    <Page>
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead sx={{ bgcolor: 'alternate.main' }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Add Package</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allUsers.map((item, i) => (
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <List sx={{ p: 0, m: 0 }}>
                      <ListItem sx={{ p: 0, m: 0 }}>
                        <ListItemAvatar>
                          <Avatar src="https://assets.maccarianagency.com/avatars/img5.jpg" />
                        </ListItemAvatar>
                        <ListItemText primary={item.firstName + ' ' + item.lastName} secondary={item.emailAddress} />
                      </ListItem>
                    </List>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select
                        id="select-package"
                        value={packageCode}
                        // onChange={handleAddPackageChange}
                      >
                        {allPackages.map((pack, i) => (
                          <MenuItem value={item.emailAddress + '||' + pack.code}>
                            <ListItemText primary={pack.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}

export default User;
