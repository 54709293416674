import { Environment, EnvironmentConfig } from '../types/app';

export function getEnvValues(): EnvironmentConfig {
  const results: EnvironmentConfig = {
    authDomain: '',
    authClientId: '',
    baseApiUrl: '',
  };

  const envSpace = process.env.NODE_ENV;

  if (envSpace === Environment.CODESPACE) {
    results.authDomain = 'dev-hiyyxs3r20d8ijj4.us.auth0.com';
    results.authClientId = 'lHM453aY4l1SZDbbI1Qa0pKRo2i4F0l7';
    results.baseApiUrl = 'https://tn531-glorious-guide-pjprwxjgqvf975j-8000.app.github.dev';
  } else {
    results.authDomain = '101-license-academy.us.auth0.com';
    results.authClientId = 'lzc9RzZ6oYQqe8i81OzdChn4odzVPNRd';
    results.baseApiUrl = 'https://api.101licenseacademy.com';
  }
  return results;
}
