// Routes
import { Route, Routes } from 'react-router-dom';

// Components
import MyCourses from '../pages/MyCourses';
import MyCertificates from '../pages/MyCertificates';
import MyAccount from '../pages/MyProfile';
import MyExams from '../pages/MyExams/MyExams';
import Overview from '../pages/Exam';
import NotFound from '../components/NotFound';

const AccountRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MyCourses />} />
      <Route path="courses" element={<MyCourses />} />
      <Route path="exams" element={<MyExams />} />
      <Route path="start_exam" element={<Overview />} />
      <Route path="certificates" element={<MyCertificates />} />
      <Route path="profile" element={<MyAccount />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AccountRoutes;
