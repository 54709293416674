import { CourseType, PackageType } from '../types/system';
import { UserAnswerType, UserType } from '../types/user';
import { getEnvValues } from '../utils/env_utils';

const BASE_URL = getEnvValues().baseApiUrl;
const SUB_PATH = 'user_contract';

const DEFAULT_POST_ARG = {
  method: 'POST',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
};

function getPostArgWithData(data: any): any {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
}

export default {
  createEnrollmentAfterPurchasingCourse: async (userId: string, courseCode: string): Promise<PackageType[]> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/create_enrollment_after_purchasing_course?userId=${userId}&courseCode=${courseCode}`;
    return await (await fetch(endpoint)).json();
  },
  createEnrollmentsAfterPurchasingPackage: async (userId: string, packageCode: string): Promise<CourseType[]> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/create_enrollments_after_purchasing_package?userId=${userId}&packageCode=${packageCode}`;
    return await (await fetch(endpoint)).json();
  },
  refreshEnrollments: async (userId: string): Promise<UserType[]> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/refresh_enrollments?userId=${userId}`;
    return await (await fetch(endpoint, getPostArgWithData({}))).json();
  },
  refreshUserExams: async (userId: string): Promise<UserType[]> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/refresh_user_exams?userId=${userId}`;
    return await (await fetch(endpoint, getPostArgWithData({}))).json();
  },
  startExam: async (examId: string): Promise<string> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/start_exam?userExamId=${examId}`;
    return await (await fetch(endpoint, DEFAULT_POST_ARG)).json();
  },
  finishExam: async (examId: string, answers: UserAnswerType[]): Promise<string> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/finish_exam?userExamId=${examId}`;
    return await (await fetch(endpoint, getPostArgWithData(answers))).json();
  },
};
