import { Typography } from '@mui/material';

interface TimerProps {
  timeLeft: number; // Time left in seconds
}

const Timer: React.FC<TimerProps> = ({ timeLeft }) => {
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <Typography align={'center'} variant={'subtitle2'} color="text.secondary" gutterBottom>
      Remaing Time: {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
    </Typography>
  );
};

export default Timer;
