import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

// Types
import { EnrollmentType } from '../../../../types/user';
import { getHumanReadableUserEnrollmentStatus } from '../../../../utils/model_status_utils';

type CourseDetailsModalProps = {
  children: {
    onClose: any;
    open: boolean;
    enrollment: EnrollmentType;
  };
};

function createTableData(key: string, val: string) {
  return { key, val };
}

/* 
  1. First Accessed Time
  2. Last Accessed Time
  3. Completed Date
  4. Avg. Quiz score
*/
function getTableData(enrollment: EnrollmentType) {
  const rows = [
    createTableData('Course Title', enrollment.course.title),
    createTableData('Course Status', getHumanReadableUserEnrollmentStatus(enrollment.status)),
    createTableData('Progress', enrollment.progressPercent + '%'),
    createTableData('Time Spent (minutes)', enrollment.timeSpentInMinute.toString()),
  ];
  return rows;
}

const CourseDetails = (props: CourseDetailsModalProps) => {
  const { onClose, open, enrollment } = props.children;
  const rows = getTableData(enrollment);

  return (
    <Dialog onClose={onClose} open={open} maxWidth={'lg'}>
      <Box paddingX={{ xs: 2, sm: 4 }} paddingY={{ xs: 1, sm: 2 }}>
        <Box paddingY={{ xs: 1, sm: 2 }} display={'flex'} justifyContent={'Center'}>
          <Typography fontWeight={700}> About your course </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }}>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.key}>
                      <TableCell scope="row">{row.key}</TableCell>
                      <TableCell align="right">{row.val}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Table>
        </TableContainer>
        <Box paddingY={{ xs: 1, sm: 2 }} display={'flex'} justifyContent={'Center'}>
          <Button onClick={onClose} variant={'contained'} size={'small'}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CourseDetails;
