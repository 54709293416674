import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Layout Components
import Main from './layouts/Main';

// Components
import NotFound from './components/NotFound';

// Pages
import ComingSoon from './pages/ComingSoon';

// Custom Routes
import AdminRoutes from './routes/admin';
import AccountRoutes from './routes/account';
import BlogRoutes from './routes/blog';
import CourseRoutes from './routes/course';
import PackageRoutes from './routes/packages';

function App() {
  return (
    <BrowserRouter>
      <Main>
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="package/*" element={<PackageRoutes />} />
          <Route path="course/*" element={<CourseRoutes />} />
          <Route path="blog/*" element={<BlogRoutes />} />
          <Route path="account/*" element={<AccountRoutes />} />
          <Route path="admin/*" element={<AdminRoutes />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Main>
    </BrowserRouter>
  );
}

export default App;
