import { Box, Container, Typography } from '@mui/material';

interface ExamCompletionProps {
  timerExpired?: boolean;
}

const Completion: React.FC<ExamCompletionProps> = ({ timerExpired = false }) => {
  return (
    <Container>
      <Box marginBottom={3}>
        <Typography variant={'h6'} fontWeight={700} gutterBottom>
          {timerExpired ? <h2>The exam has ended.</h2> : <h2>Thank you for completing the exam.</h2>}
        </Typography>
        <Typography component={'p'}>You can check the status of your results in the exam page.</Typography>
      </Box>
    </Container>
  );
};

export default Completion;
