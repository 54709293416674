import { useState } from 'react';

// Third parties
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';

// Components
import CourseDetails from '../CourseDetails';

// Types
import { EnrollmentType } from '../../../../types/user';
import { Typography } from '@mui/material';

type CourseCardProps = {
  children: EnrollmentType;
};

const handleClick = (url: string) => {
  window.open(url, '_blank');
};

const cardSx = {
  width: 1,
  height: 1,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
  bgcolor: 'transparent',
};

const imageSx = {
  position: 'relative',
  height: 320,
  overflow: 'hidden',
  borderRadius: 2,
};

const CourseCard = (props: CourseCardProps) => {
  const enrollment = props.children;
  const course = enrollment.course;

  const [open, setOpen] = useState(false);

  return (
    <Card sx={cardSx}>
      <Box marginTop={2} display={'flex'} justifyContent={'space-between'}>
        <Typography fontWeight={700} alignItems={'center'}>
          {course.title}
        </Typography>
      </Box>
      <CardMedia image={course.imageUrl || '/images/real-estate/finance.png'} sx={imageSx} />
      <Stack spacing={1} direction={'row'}>
        <Button
          onClick={() => handleClick(enrollment.launchLink)}
          variant={'contained'}
          color={'primary'}
          size={'small'}
          fullWidth
          disabled={enrollment.status === 'COMPLETED'}
        >
          Play
        </Button>
        <Button color={'primary'} variant={'contained'} size={'small'} fullWidth onClick={() => setOpen(true)}>
          Status
        </Button>
        <CourseDetails>
          {{
            open: open,
            onClose: () => setOpen(false),
            enrollment: enrollment,
          }}
        </CourseDetails>
      </Stack>
    </Card>
  );
};

export default CourseCard;
