import React from 'react';

import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const mock = [
  {
    title: 'Real Estate Principles (45 hours)',
    subtitle:
      'Foundational course covering a range of topics including property rights, estates in land, property transfer, contract law, real estate finance, agency law, and property management, providing students with a broad understanding of the fundamental concepts and practices of the real estate industry.',
    icon: '/images/icons/Stock-market.svg',
  },
  {
    title: 'Real Estate Practice (45 hours)',
    subtitle:
      'The practical application of the principles and techniques involved in real estate transactions, including property valuation, financing, negotiations, and closing procedures.',
    icon: '/images/icons/Real-estate-investing.svg',
  },
  {
    title: 'One elective course (45 hours)',
    subtitle:
      'Various subjects related to the study and practice of real estate, such as property valuation, management, finance, economics, legal aspects, accounting, business law, mortgage loan brokering and lending, computer applications, and common interest developments.',
    icon: '/images/icons/REIT.svg',
  },
];

const Requirements = () => {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h4"
        color="text.primary"
        sx={{
          fontWeight: 500,
        }}
      >
        <center>What are the required education requirements?</center>
      </Typography>

      <br />

      <Typography
        variant="h5"
        color="text.primary"
        sx={{
          fontWeight: 300,
        }}
      >
        <center>Complete 135 hours of Department of Real Estate approved college-level real estate courses.</center>
      </Typography>

      <br />
      <br />

      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              component={'a'}
              href={''}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                data-aos={'fade-up'}
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
                flexDirection={'column'}
                display={'flex'}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Box
                    component={'img'}
                    justifyContent={'center'}
                    width={{ xs: 70, md: 90 }}
                    height={{ xs: 70, md: 90 }}
                    marginBottom={2}
                    src={item.icon}
                  />
                  <Typography variant={'h6'} sx={{ fontWeight: 500 }}>
                    {item.title}
                  </Typography>
                  <Typography color="text.secondary">{item.subtitle}</Typography>
                </CardContent>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Requirements;
