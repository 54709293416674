import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Page from '../../layouts/Account/Account';
import Loading from '../../components/Loading';
import NotAuthenticated from '../../components/NotAuthenticated/NotAuthenticated';

const MyProfile = () => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  if (isLoading || user === undefined) {
    return <Loading />;
  } else if (!isAuthenticated) {
    return <NotAuthenticated />;
  }

  // const onSubmit = (values) => {
  //   return values;
  // };

  return (
    <Page>
      <Box>
        <Typography variant="h6" gutterBottom fontWeight={700}>
          Change your private information
        </Typography>
        <Box paddingY={4}>
          <Divider />
        </Box>
        <form>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }} fontWeight={700}>
                Enter your first name
              </Typography>
              <TextField
                label="First name *"
                variant="outlined"
                name={'firstName'}
                fullWidth
                value={user.name?.split(' ')[0]}
                // onChange={''}
                helperText={''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }} fontWeight={700}>
                Enter your last name
              </Typography>
              <TextField
                label="Last name *"
                variant="outlined"
                name={'lastName'}
                fullWidth
                value={user.name?.split(' ')[1]}
                // onChange={''}
                // error={''}
                helperText={''}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }} fontWeight={700}>
                Bio
              </Typography>
              <TextField
                label="Bio"
                variant="outlined"
                name={'bio'}
                multiline
                rows={5}
                fullWidth
                value={''}
                // onChange={''}
                // error={''}
                helperText={''}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }} fontWeight={700}>
                Country
              </Typography>
              <TextField
                label="Country *"
                variant="outlined"
                name={'country'}
                fullWidth
                value={'USA'}
                // onChange={''}
                // error={''}
                helperText={''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }} fontWeight={700}>
                City
              </Typography>
              <TextField
                label="City *"
                variant="outlined"
                name={'city'}
                fullWidth
                value={'San Francisco'}
                // onChange={''}
                // error={''}
                helperText={''}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }} fontWeight={700}>
                Enter your address
              </Typography>
              <TextField
                label="Address *"
                variant="outlined"
                name={'address'}
                fullWidth
                value={user.email}
                // onChange={''}
                // error={''}
                helperText={''}
                disabled={true}
              />
            </Grid>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'center' }}
                justifyContent={'space-between'}
                width={1}
                margin={'0 auto'}
              >
                <Button size={'large'} variant={'contained'} type={'submit'}>
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      `
    </Page>
  );
};

export default MyProfile;
