import { getRealEstateLicensePathByState } from '../routes/utils';

const pages = {
  realestate: [
    {
      title: 'California',
      href: getRealEstateLicensePathByState('CA'),
    },
    {
      title: 'Texas',
      href: getRealEstateLicensePathByState('TX'),
    },
    {
      title: 'Florida',
      href: getRealEstateLicensePathByState('FL'),
    },
  ],
  blog: [
    {
      title: 'Category 1',
      href: '/category1/',
    },
  ],
  guide: [
    {
      title: 'Starting a career in Real Estate',
      href: '/blog-reach-view',
    },
  ],
  myCourses: [
    {
      title: 'Courses',
      href: '/account/courses/',
    },
    {
      title: 'Exams',
      href: '/account/exams/',
    },
    // {
    //   title: 'Certificates',
    //   href: '/account/certificates/',
    // },
    {
      title: 'Profile',
      href: '/account/profile/',
    },
    // {
    //   title: 'Billing',
    //   href: '/account/billing/',
    // },
  ],
  adminPages: [
    {
      title: 'Users',
      href: '/admin/users/',
    },
    {
      title: 'Enrollments',
      href: '/admin/enrollments/',
    },
  ],
};

export default pages;
