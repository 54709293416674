import React from 'react';

import { Box, Radio, RadioGroup, Typography } from '@mui/material';
import { UserAnswerType } from '../../../../types/user';

interface ChoiceProps {
  question: {
    num: number;
    prompt: string;
    choices: Array<{ id: string; text: string }>;
  };
  selectedAnswers: UserAnswerType[];
  currentQuestionIndex: number;
  setSelectedAnswers: any;
}

const MultipleChoiceOption: React.FC<ChoiceProps> = ({
  question,
  selectedAnswers,
  currentQuestionIndex,
  setSelectedAnswers,
}) => {
  return (
    <RadioGroup>
      {question.choices.map((choice) => (
        <label>
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            <Radio
              id={choice.id}
              value={choice.text}
              checked={
                selectedAnswers[currentQuestionIndex] !== null &&
                selectedAnswers[currentQuestionIndex].answer.id === choice.id
              }
              onChange={(e) => {
                selectedAnswers[currentQuestionIndex] = {
                  num: question.num,
                  prompt: question.prompt,
                  answer: {
                    id: e.target.id,
                    isSelected: true,
                    isCorrect: false,
                    text: e.target.value,
                  },
                };
                setSelectedAnswers(selectedAnswers);
              }}
            />
            <Typography component={'p'}>{choice.text}</Typography>
          </Box>
        </label>
      ))}
    </RadioGroup>
  );
};

export default MultipleChoiceOption;
