export enum Environment {
  CODESPACE = 'development',
  PRODUCTION = 'PRODUCTION',
}

export enum Application {
  MAIN = 'MAIN',
  TEST = 'TEST',
}

export type EnvironmentConfig = {
  authDomain: string;
  authClientId: string;
  baseApiUrl: string;
};
