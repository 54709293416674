import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Page from '../../layouts/Account/Account';
import { EnrollmentType } from '../../types/user';

import Container from '@mui/material/Container/Container';
import Grid from '@mui/material/Grid';

import API from '../../services/model_endpoints';

import CourseCard from './components/CourseCard';
import Loading from '../../components/Loading';
import NotAuthenticated from '../../components/NotAuthenticated/NotAuthenticated';
import user_contract from '../../services/user_contract';

const MyCourses = () => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const [enrollments, setEnrollments] = useState<EnrollmentType[]>([]);

  useEffect(() => {
    async function fetchData() {
      if (user?.email !== undefined) {
        user_contract.refreshEnrollments(user?.email);
        const enrollments = await API.fetchEnrollmentsByUser(user?.email);
        setEnrollments(enrollments);
      }
    }
    fetchData();
  }, [user?.email]);

  if (isLoading) {
    return <Loading />;
  } else if (!isAuthenticated) {
    return <NotAuthenticated />;
  }

  return (
    <Page>
      <Container>
        <Grid container spacing={5}>
          {enrollments.map((item: EnrollmentType, i: number) => (
            <Grid item sm={6} md={3} key={i}>
              <CourseCard>{item}</CourseCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

export default MyCourses;
