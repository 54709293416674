// Routes
import { Route, Routes } from 'react-router-dom';

// Internal components
import Blog from '../pages/Blog';
import NotFound from '../components/NotFound';

const BlogRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Blog />} />
      {/* <Route path="/category/:categoryName" element={<BlogCategory />} />
      <Route path="/post/:postId" element={<BlogPost />} /> */}
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default BlogRoutes;
