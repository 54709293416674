import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import Container from '../../../components/ui/Container/container';

const mock = [
  {
    title: 'Real Estate Principles',
    description:
      'Real estate principles are the fundamental concepts that guide the real estate industry. These principles include understanding the nature of property rights, the concept of supply and demand, and the importance of location. Property rights refer to the legal ownership and use of land and buildings, while supply and demand dictate the price and availability of real estate in the market. The location of a property is also a critical factor in determining its value, as it can affect accessibility, desirability, and market demand. Other principles include financing, property management, and real estate law, all of which are essential in navigating the complex world of real estate transactions. By understanding these principles, individuals and businesses can make informed decisions when buying, selling, or investing in real estate.',
    illustration: '/images/icons/Stock-market.svg',
    color: colors.blue[200],
  },
  {
    title: 'Real Estate Finance',
    description:
      'Real estate finance is a branch of finance that focuses on the monetary aspects of real estate transactions. It encompasses the various methods and tools used to fund the purchase, development, and management of real estate properties. Real estate finance involves the analysis of financial risks and returns associated with different real estate investment opportunities. Common methods of financing real estate include mortgages, commercial loans, and equity financing. Other considerations in real estate finance include the impact of interest rates, the use of leverage, and the assessment of property values. Efficient real estate finance is critical to the success of real estate transactions, as it enables individuals and businesses to acquire the necessary capital for their real estate investments while minimizing financial risks.      ',
    illustration: '/images/icons/Real-estate-investing.svg',
    color: colors.purple[200],
  },
  {
    title: 'Real Estate Practice',
    description:
      'Real estate practice refers to the application of the principles and concepts of real estate in actual business transactions. It involves the buying, selling, renting, and management of real estate properties. Real estate practice also includes activities such as property appraisal, property inspection, and contract negotiation. Real estate professionals, such as real estate agents, brokers, and property managers, are responsible for facilitating these transactions and ensuring that they comply with legal and ethical standards. Effective real estate practice requires a thorough understanding of real estate principles and finance, as well as the ability to communicate and negotiate effectively with clients and other stakeholders. By following best practices and adhering to industry standards, real estate professionals can help their clients achieve their real estate goals while minimizing risks and maximizing returns.',
    illustration: '/images/icons/REIT.svg',
    color: colors.indigo[200],
  },
  {
    title: 'Real Estate Math',
    description:
      'A real estate math course is designed to provide real estate professionals with the necessary mathematical skills and knowledge required to succeed in the industry. The course typically covers a range of topics such as real estate finance, property valuation, and investment analysis. It also includes topics such as basic arithmetic, algebra, and geometry, which are essential in performing real estate calculations. Real estate math courses are important for individuals pursuing careers as real estate agents, brokers, appraisers, and investors. The course helps students to develop a deep understanding of the financial and quantitative aspects of real estate transactions, which is critical to making informed decisions and maximizing profits. With the increasing complexity of real estate transactions and the importance of financial analysis in the industry, a real estate math course has become an indispensable tool for anyone seeking to build a successful career in real estate.',
    illustration: '/images/icons/Properties.svg',
    color: colors.green[200],
  },
];

const CourseBlocks = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Container>
      <Typography
        variant="h4"
        color="text.primary"
        sx={{
          fontWeight: 500,
        }}
      >
        <center>What are the courses we provide?</center>
      </Typography>

      <br />
      <br />

      <Grid container spacing={isMd ? 8 : 4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} md={6}>
            <Box
              component={Card}
              height={1}
              bgcolor={alpha(item.color, 0.2)}
              boxShadow={0}
              sx={{
                transform: i % 2 === 1 ? { md: 'translateY(80px)' } : 'none',
              }}
            >
              <CardContent sx={{ padding: 4 }}>
                <Box display={'flex'} justifyContent={'center'} marginBottom={{ xs: 2, md: 4 }}>
                  <Box component={'img'} src={item.illustration} width={1} maxWidth={{ xs: '80%', md: '70%' }} />
                </Box>
                <Box>
                  <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 700 }}>
                    {item.title}
                  </Typography>
                  <Typography color="text.secondary">{item.description}</Typography>
                  <Button
                    size={'large'}
                    sx={{ marginTop: 2 }}
                    endIcon={
                      <Box
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </Box>
                    }
                  >
                    Learn More
                  </Button>
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CourseBlocks;
