import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; 101LicenseAcademy {currentYear}. All rights reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
