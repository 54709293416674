// Routes
import { Route, Routes } from 'react-router-dom';
import RealEstatePreLicensePackageRoutes from './real-estate/prelicense';
import NotFound from '../components/NotFound';

// Components

const PackageRoutes = () => {
  return (
    <Routes>
      <Route path="real-estate/*" element={<RealEstatePreLicensePackageRoutes />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default PackageRoutes;
