import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const EnrollButton = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <>
      <Box
        href="/purchase"
        component={Button}
        variant="outlined"
        color="primary"
        size="large"
        marginTop={{ xs: 2, sm: 0 }}
        marginLeft={{ sm: 2 }}
        fullWidth={isMd ? false : true}
      >
        Enroll Today
      </Box>
    </>
  );
};

export default EnrollButton;
