import React from 'react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Page from '../../layouts/Account';
import Container from '../../components/ui/Container/container';
import { CertificateType } from '../../types/system';

const certificateSampleOne: CertificateType = {
  courseTitle: 'Real Estate Principle',
  startDate: new Date(2023, 4, 1),
  completedDate: new Date(2023, 4, 1),
  status: 'Completed',
  timeSpentInMinutes: 120,
  creditUnits: 120,
  creditUnitType: 'Hours',
  scorePrecentage: 90,
  pdfUrl: '/certificates/realestate-principles.pdf',
};

const certificateSampleTwo: CertificateType = {
  courseTitle: 'Real Estate Finance',
  startDate: new Date(2023, 4, 1),
  completedDate: new Date(2023, 4, 1),
  status: 'Completed',
  timeSpentInMinutes: 120,
  creditUnits: 120,
  creditUnitType: 'Hours',
  scorePrecentage: 90,
  pdfUrl: '/certificates/realestate-principles.pdf',
};

const certificateSampleThree: CertificateType = {
  courseTitle: 'Real Estate Practice',
  startDate: new Date(2023, 4, 1),
  completedDate: new Date(2023, 4, 1),
  status: 'Completed',
  timeSpentInMinutes: 120,
  creditUnits: 120,
  creditUnitType: 'Hours',
  scorePrecentage: 90,
  pdfUrl: '/certificates/realestate-principles.pdf',
};

const certificates: CertificateType[] = [certificateSampleOne, certificateSampleTwo, certificateSampleThree];

const handleClick = () => {
  window.open('/images/certificates/realestate-principles.pdf', '_blank');
};

function MyCertificates() {
  const theme = useTheme();
  return (
    <Page>
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead sx={{ bgcolor: 'alternate.dark' }}>
              <TableRow>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Course Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Start Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Completed Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Score
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Time Spent
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'caption'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>
                    Credits
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {certificates.map((item: CertificateType, i: number) => (
                <TableRow
                  key={i}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:nth-of-type(2n)': { bgcolor: 'alternate.main' },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant={'subtitle2'} fontWeight={700}>
                      {item.courseTitle}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={'text.secondary'} variant={'subtitle2'}>
                      {item.startDate.toLocaleDateString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={'text.secondary'} variant={'subtitle2'}>
                      {item.completedDate.toLocaleDateString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'caption'} fontWeight={700} sx={{ color: theme.palette.success.dark }}>
                      {item.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={'text.secondary'} variant={'subtitle2'}>
                      {item.scorePrecentage}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography color={'text.secondary'} variant={'subtitle2'}>
                      {item.timeSpentInMinutes}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography color={'text.secondary'} variant={'subtitle2'}>
                      {item.creditUnits}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      onClick={handleClick}
                      color={'primary'}
                      variant={'subtitle2'}
                      fontWeight={700}
                      sx={{ cursor: 'pointer' }}
                    >
                      Download
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}

export default MyCertificates;
