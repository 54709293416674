import React from 'react';
import { Avatar, Box, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import CheckMarkButton from '../../../../../components/app/ui/buttons/CheckMark/CheckMark';

interface SegementProps {
  title: string;
  paragraph?: string;
  bulletPoints: string[];
}

const Segment: React.FC<SegementProps> = ({ title, paragraph, bulletPoints }) => {
  return (
    <Box marginBottom={3}>
      <Typography variant={'h5'} fontWeight={700}>
        {title}
      </Typography>

      <Typography component={'p'}>{paragraph}</Typography>

      <Grid container spacing={1} sx={{ marginTop: 1 }}>
        {bulletPoints.map((item, i) => (
          <Grid item xs={12} key={i}>
            <Box component={ListItem} width={'auto'} padding={0}>
              <Box component={ListItemAvatar} minWidth={'auto !important'} marginRight={2}>
                <Box component={Avatar} bgcolor="primary.dark" width={20} height={20}>
                  <CheckMarkButton />
                </Box>
              </Box>
              <ListItemText primary={item} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Segment;
