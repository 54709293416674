import { CourseType, PackageType } from '../types/system';
import { UserType } from '../types/user';
import { getEnvValues } from '../utils/env_utils';

const BASE_URL = getEnvValues().baseApiUrl;
const SUB_PATH = 'admin_contract';

const DEFAULT_POST_ARG = {
  method: 'POST',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
};

export default {
  createEnrollmentsForUser: async (userId: string, packageCode: string): Promise<PackageType[]> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/create_enrollments_for_user?userId=${userId}&packageCode=${packageCode}`;
    return await (await fetch(endpoint, DEFAULT_POST_ARG)).json();
  },
  deleteUserEnrollmentsForPackage: async (userId: string, packageCode: string): Promise<CourseType[]> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/delete_user_enrollments_for_package?userId=${userId}&packageCode=${packageCode}`;
    return await (await fetch(endpoint, DEFAULT_POST_ARG)).json();
  },
  deleteEnrollementAndRegistration: async (enrollmentId: string): Promise<UserType[]> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/delete_enrollment_and_registration?enrollmentId=${enrollmentId}`;
    return await (await fetch(endpoint, DEFAULT_POST_ARG)).json();
  },
  resetEnrollmentAndRegistration: async (enrollmentId: string): Promise<null> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/reset_enrollment_and_registration?enrollmentId=${enrollmentId}`;
    return await (await fetch(endpoint, DEFAULT_POST_ARG)).json();
  },
  markEnrollmentCompleted: async (enrollmentId: string): Promise<null> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/mark_enrollment_completed?enrollmentId=${enrollmentId}`;
    return await (await fetch(endpoint, DEFAULT_POST_ARG)).json();
  },
  refreshEnrollment: async (enrollmentId: string): Promise<null> => {
    const endpoint = `${BASE_URL}/${SUB_PATH}/refresh_enrollment?enrollmentId=${enrollmentId}`;
    return await (await fetch(endpoint, DEFAULT_POST_ARG)).json();
  },
};
