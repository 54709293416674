import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '../../../../components/ui/Container/container';
import CtaSection from '../CtaSection';
import CoverSlider from '../CoverSlider';

const CourseHeroImage = () => (
  <Box
    sx={{
      width: 1,
      height: 1,
      overflow: 'hidden',
    }}
  >
    <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'row' }}
        position={'relative'}
        minHeight={{ xs: 'auto', md: 600 }}
      >
        <Box width={1} order={{ xs: 2, md: 1 }} display={'flex'} alignItems={'center'}>
          <Container>
            <CtaSection />
          </Container>
        </Box>
        <Box
          sx={{
            flex: { xs: '0 0 100%', md: '0 0 50%' },
            position: 'relative',
            maxWidth: { xs: '100%', md: '50%' },
            order: { xs: 1, md: 2 },
          }}
        >
          <Box
            sx={{
              width: { xs: 1, md: '50vw' },
              height: '100%',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  overflow: 'hidden',
                  left: '0%',
                  width: 1,
                  height: 1,
                  position: { xs: 'relative', md: 'absolute' },
                  clipPath: {
                    xs: 'none',
                    md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                  },
                  shapeOutside: {
                    xs: 'none',
                    md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                  },
                }}
              >
                <CoverSlider />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
    <Divider />
  </Box>
);

export default CourseHeroImage;
