import Container from '../../components/ui/Container/container';
import CourseBlocks from '../LandingPage/components/CourseBlocks';
import EnrollButton from '../../components/ui/buttons/EnrollNow';
import CourseHeroImage from './components/HeroImage/HeroImage';
import Requirements from './components/Requirements';

const FAQs = () => {
  const faqData = [
    {
      question: 'How long does it take to get a real estate license in California?',
      answer:
        'The process typically takes between 3-6 months, depending on factors such as course completion, exam scheduling, and application processing times.',
    },
    {
      question: 'What is the cost of obtaining a real estate license in California?',
      answer:
        'Costs may vary, but expenses typically include course fees, exam fees, license application fees, and fingerprinting/background check fees.',
    },
    {
      question: 'Can I take the required courses online?',
      answer:
        'Yes, many DRE-approved education providers offer online courses that fulfill the required coursework for real estate licensure.',
    },
    {
      question: 'How often do I need to renew my real estate license?',
      answer:
        'In California, real estate licenses must be renewed every four years. Licensees are also required to complete continuing education courses during each renewal cycle.',
    },
  ];

  return (
    <div className="faqs-container">
      <h2 className="faqs-title">Frequently Asked Questions</h2>
      <div className="faqs-content">
        {faqData.map((faq, index) => (
          <div key={index} className="faq">
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const Course = () => {
  return (
    <>
      <CourseHeroImage />
      <Container>
        <Requirements />
      </Container>
      <CourseBlocks />

      <center>
        <br />
        <br />
        <br />
        <EnrollButton />
      </center>
      {/* <RealEstateAgentHero/>
            <CaliforniaRealEstateCourses/>
            <LicensingProcessTimeline />
            <FAQs />
            <Requirements /> */}
    </>
  );
};

export default Course;
